<template>
  <div>
    <base-material-card color="drawerColorSecondary">
      <template v-slot:heading>
        <div class="display-2 font-weight-light">Paquetes</div>
      </template>

      <v-combobox
        v-model="paquete"
        :items="this.paquetesPublicos"
        item-text="nombre"
        item-value="idPaquete"
        label="Seleccione un Paquete"
        class="margenMaterial"
        return-object
        auto-select-first
      />
      <v-btn color="verdeBoton" :disabled="colorBotonEnviar" @click="agregarPaquete()">Agregar</v-btn>
      <v-btn
        color="verdeBoton"
        :disabled="this.prestamos.length > 0 ? false : true"
        @click="paqueteVisible = !paqueteVisible"
      >Generar</v-btn>
    </base-material-card>

    <v-dialog v-model="paqueteVisible" width="600">
      <v-card>
        <v-card-text>
          <base-material-card color="drawerColorSecondary">
            <template v-slot:heading>
              <div class="display-2 font-weight-light">Generar Paquete Publico</div>
            </template>
            <v-text-field v-model="nombrePaquete" label="Nombre del Paquete" />

            <v-btn color="verdeBoton" @click="paqueteVisible = !paqueteVisible">Cancelar</v-btn>
            <v-btn
              color="verdeBoton"
              :disabled="this.nombrePaquete.length > 0 ? false : true"
              @click="generarPaquete()"
            >Aceptar</v-btn>
          </base-material-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "paquetes",
  data() {
    return {
      colorGenerar: true,
      colorBotonEnviar: true,
      idCuenta: this.$store.state.cuenta.idCuenta,
      idPaquete: "",
      paquete: "",
      paquetesPublicos: [],
      materialesPaquete: [],
      arregloMateriales: [],
      materialPaquete: "",
      nombrePaquete: "",
      paqueteVisible: false,
    };
  },
  mounted() {
    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/paquetes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((paquetes) => {
        this.paquetesPublicos = paquetes;
      })
      .catch((err) => {
        return err;
      });
  },
  props: {
    prestamos: Array,
    cuenta: {},
  },
  watch: {
    paquete() {
      this.validarBotonEnviar();
    },
  },
  methods: {
    /**
     * Se agrega al campo de descripcion para validar que solo se ingresen numeros en caso
     * de tener un potenciador
     * @$event
     */
    revisarSintaxis($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    //Verificamos que deba estar habilitado el boton de enviar
    validarBotonEnviar() {
      if (this.paquete == null) {
        return;
      }
      this.colorBotonEnviar = true;
      if (this.paquete.idPaquete >= 1) {
        this.colorBotonEnviar = false;
      } else this.colorBotonEnviar = true;
    },
    /**
     * Aqui hacemos la peticion del paquete para ser agregado
     */
    agregarPaquete() {
      fetch(
        process.env.VUE_APP_URL_BACKEND +
          "/administrador/paquetes/" +
          this.paquete.idPaquete,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.cuenta.token,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((materialesPaquete) => {
          //console.log("prestamo");
          this.arregloMateriales = materialesPaquete;
          let prestamos = [];
          for (let i = 0; i < this.arregloMateriales.length; i++) {
            let prestamoNuevo = {
              idCuenta: this.cuenta.idCuenta,
              descripcion: this.arregloMateriales[i].descripcion,
              potenciador: this.arregloMateriales[i].potenciador,
              idMaterial: this.arregloMateriales[i].idMaterial,
              material: this.arregloMateriales[i].nombre,
              unidadM: this.arregloMateriales[i].unidadMedida,
            };
            prestamos.push(prestamoNuevo);
          }
          prestamos.forEach((element) => {
            fetch(
              process.env.VUE_APP_URL_BACKEND + "/administrador/prestamos",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + this.$store.state.cuenta.token,
                },
                body: JSON.stringify(element)
              }
            ).then(response => {
              return response.json();
            }).then(prestamoCreado => {
              this.prestamos.push(prestamoCreado);
            });
          });
        })
        .catch((err) => {
          return err;
        });
    },
    generarPaquete() {
      this.paquetesVisible = !this.paquetesVisible;
    },
  },
};
</script>

<style>
#bodyBase {
  background-color: #fffcf7;
}

.margenMaterial {
  margin-left: 10px;
  margin-right: 10px;
}

.ajusteIzquierda {
  margin-left: 10px;
}
.ajusteDerecha {
  margin-right: 10px;
}

#bodyCol4 {
  height: 520px;
}
</style>
<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="4" xs="12">
        <menuMateriales :materiales="materiales" :prestamos="prestamos" :cuenta="cuenta" />
        <paquetes :prestamos="prestamos" :cuenta="cuenta" />
      </v-col>
      <v-col cols="12" md="8">
        <tablaPrestamos :sizeScreen="sizeScreen" :prestamos="prestamos" :cuenta="cuenta" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import menuMateriales from "../component/menuMateriales";
import paquetes from "../component/paquetes";
import tablaPrestamos from "../component/tablaPrestamos";

export default {
  name: "GenerarPrestamos",
  components: {
    paquetes,
    menuMateriales,
    tablaPrestamos,
  },
  data() {
    return {
      mask: "#",
      materiales: [],
      prestamos: [],
      potenciadoNuevo: "",
      cantidadNuevo: "",
      descripcionMaterial: "",
      isDescripcion: true,
      isUnidad: true,
      cantidadMaterial: 0,
      sizeScreen: 0,
      dialogMaterial: false,
    };
  },
  computed: {
    ...mapState(["cuenta"]),
  },
  created() {
    //Metodo para detectar el tamanio de pantalla inicial
    this.sizeScreen = window.innerWidth;
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    /**
     * Aqui hacemos la peticion de los materiales para ser utilizados
     * Se almacenan todos los materiales en un array.
     */
    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/materiales", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((materiales) => {
        this.materiales = materiales;
      })
      .catch((err) => {
        return err;
      });

    
  },
  methods: {
    //Metodo para detectar cambios de tamanio en la pantalla
    myEventHandler() {
      this.sizeScreen = window.innerWidth;
    },

    /**
     * Metodo para limpiar las variables principales del material
     */
    limpiarMaterial() {
      this.dialogMaterial = false;
      this.materialNuevo = null;
      this.descripcionMaterial = "";
      this.potenciadoNuevo = "";
      this.isDescripcion = true;
      this.isUnidad = true;
    },
  },
};
</script>

<style>
#bodyBase {
  background-color: #fffcf7;
}

.margenMaterial {
  margin-left: 10px;
  margin-right: 10px;
}

.ajusteIzquierda {
  margin-left: 10px;
}
.ajusteDerecha {
  margin-right: 10px;
}

#bodyCol4 {
  height: 520px;
}
</style>
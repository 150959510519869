var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Préstamo")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"verdeBoton","disabled":_vm.colorBotonEnviar},on:{"click":function($event){return _vm.enviarPrestamos()}}},[_vm._v("Enviar Préstamo")])],1),(_vm.sizeScreen <= 500)?_c('div',[_c('v-dialog',{model:{value:(_vm.dialogMaterial),callback:function ($$v) {_vm.dialogMaterial=$$v},expression:"dialogMaterial"}},[_c('v-card',[_c('v-card-text',[_c('base-material-card',{attrs:{"color":"indigo darken-4"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-medium"},[_vm._v("Material")])]},proxy:true}],null,false,3576022303)},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"md3":""}},[_c('v-text-field',{staticClass:"margenMaterial",attrs:{"disabled":true,"item-text":"nombre","label":"Material"},model:{value:(_vm.materialNuevo),callback:function ($$v) {_vm.materialNuevo=$$v},expression:"materialNuevo"}})],1),_c('v-flex',{attrs:{"md5":""}},[_c('v-text-field',{staticClass:"margenMaterial",attrs:{"disabled":true,"label":"Descripción"},model:{value:(_vm.descripcionMaterial),callback:function ($$v) {_vm.descripcionMaterial=$$v},expression:"descripcionMaterial"}})],1),_c('v-flex',{attrs:{"md2":""}},[_c('v-text-field',{staticClass:"margenMaterial",attrs:{"disabled":true,"label":"Potenciador"},model:{value:(_vm.potenciadoNuevo),callback:function ($$v) {_vm.potenciadoNuevo=$$v},expression:"potenciadoNuevo"}})],1)],1),_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.limpiarMaterial()}}},[_vm._v("Aceptar")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 category-table",attrs:{"headers":_vm.headersMini,"items":_vm.prestamos,"items-per-page":10,"footer-props":{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus'
                },"dense":"","no-data-text":"No se Encuentran Préstamos"},scopedSlots:_vm._u([{key:"body",fn:function({items}){return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:`${i}-${item.material}`},[_c('td',[_vm._v(_vm._s(item.material))]),_c('td',[_c('v-icon',{attrs:{"medium":"","color":"primary"},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v("mdi-eye")])],1),_c('td',[_c('v-icon',{attrs:{"medium":"","color":"primary"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])],1)])}),0)]}}],null,false,4042759490)})],1):_vm._e(),(_vm.sizeScreen > 500)?_c('div',[_c('v-responsive',{staticClass:"overflow-y-auto",attrs:{"max-height":"calc(90vh - 150px)"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.prestamos,"items-per-page":10,"footer-props":{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus'
                },"dense":"","no-data-text":"No se Encuentran Préstamos"},scopedSlots:_vm._u([{key:"item.accion",fn:function({ item }){return [_c('v-icon',{attrs:{"medium":"","color":"primary"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,false,846958319)})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-card-title>
      <div class="display-2 font-weight-light">Préstamo</div>
      <v-spacer />
      <v-btn
        color="verdeBoton"
        :disabled="colorBotonEnviar"
        @click="enviarPrestamos()"
      >Enviar Préstamo</v-btn>
    </v-card-title>
    <div v-if="sizeScreen <= 500">
      <v-dialog v-model="dialogMaterial">
        <v-card>
          <v-card-text>
            <base-material-card color="indigo darken-4">
              <template v-slot:heading>
                <div class="display-2 font-weight-medium">Material</div>
              </template>
              <v-layout row>
                <v-flex md3>
                  <v-text-field
                    v-model="materialNuevo"
                    :disabled="true"
                    item-text="nombre"
                    label="Material"
                    class="margenMaterial"
                  />
                </v-flex>
                <v-flex md5>
                  <v-text-field
                    v-model="descripcionMaterial"
                    :disabled="true"
                    label="Descripción"
                    class="margenMaterial"
                  />
                </v-flex>
                <v-flex md2>
                  <v-text-field
                    v-model="potenciadoNuevo"
                    :disabled="true"
                    label="Potenciador"
                    class="margenMaterial"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-btn color="green" @click=" limpiarMaterial()">Aceptar</v-btn>
            </base-material-card>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-data-table
        :headers="headersMini"
        :items="prestamos"
        :items-per-page="10"
        :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus'
                  }"
        class="elevation-1 category-table"
        dense
        no-data-text="No se Encuentran Préstamos"
      >
        <template v-slot:body="{items}">
          <tbody>
            <tr v-for="(item, i) in items" :key="`${i}-${item.material}`">
              <td>{{item.material}}</td>
              <td>
                <v-icon medium color="primary" @click="viewItem(item)">mdi-eye</v-icon>
              </td>
              <td>
                <v-icon medium color="primary" @click="deleteItem(item)">mdi-delete</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>

    <div v-if="sizeScreen > 500">
      <v-responsive class="overflow-y-auto" max-height="calc(90vh - 150px)">
        <v-data-table
          :headers="headers"
          :items="prestamos"
          :items-per-page="10"
          :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus'
                  }"
          class="elevation-2"
          dense
          no-data-text="No se Encuentran Préstamos"
        >
          <template v-slot:item.accion="{ item }">
            <v-icon medium color="primary" @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-responsive>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "tablaPrestamos",
  data() {
    return {
      dialogMaterial: false,
      headers: [
        { text: "Material", value: "material" },
        { text: "Descripcion", value: "descripcion" },
        { text: "Potenciador", value: "potenciador" },
        { text: "Unidad De Medida", value: "unidadM" },
        { text: "Acciones", value: "accion", sortable: false, width: "8%" },
      ],
      headersMini: [
        { text: "Material", value: "material", width: "60%" },
        { text: "", value: "accion", sortable: false, width: "40%" },
      ],
      colorBotonEnviar: true,
      materialNuevo: null,
      descripcionMaterial: "",
      potenciadoNuevo: "",
    };
  },
  props: {
    sizeScreen: Number,
    prestamos: Array,
    cuenta: {},
  },
  mounted() {
    fetch(
      process.env.VUE_APP_URL_BACKEND +
        "/administrador/prestamos/solicitados/" +
        this.$store.state.cuenta.idCuenta,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.cuenta.token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((prestamos) => {
        if (prestamos.length > 0) {
          prestamos.forEach((prestamo) => {
            this.prestamos.push(prestamo);
          });
        }
      })
      .catch((err) => {
        return err;
      });
  },
  methods: {
    /**
     * Metodo para limpiar las variables principales del material
     */
    limpiarMaterial() {
      this.dialogMaterial = false;
      this.materialNuevo = null;
      this.descripcionMaterial = "";
      this.potenciadoNuevo = "";
      this.isDescripcion = true;
      this.isUnidad = true;
    },
    validarBotonEnviar() {
      this.colorBotonEnviar = true;
      if (this.prestamos.length < 1) {
        this.colorBotonEnviar = true;
      } else this.colorBotonEnviar = false;
    },
    deleteItem(item) {
      fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/prestamos", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.cuenta.token,
        },
        body: JSON.stringify({
          idPrestamo: item.idPrestamo,
        }),
      }).then((response) => {
        const posicion = this.prestamos.indexOf(item);
        this.prestamos.splice(posicion, 1);
      });
    },
    viewItem(item) {
      //let posicion = this.prestamos.indexOf(item);
      /**
       * Se encargara de poder visualizar las vairables del prestamo si es necesario, este se activa al dar la accion ver.
       * @item es el prestamo de la tabla principal el cual es un objeto de tipo material
       */
      this.materialNuevo = item.material;
      this.descripcionMaterial = item.descripcion;
      this.potenciadoNuevo = item.potenciador;
      this.dialogMaterial = !this.dialogMaterial;
    },

    /**
     * Se encarga de una vez que el alumno tenga todos su prestamos y al presionar el boton enviar
     * guarde los prestamos para que le lleguen al administrador.
     */
    enviarPrestamos() {
      for (let index = 0; index < this.prestamos.length; index++) {
        fetch(
          process.env.VUE_APP_URL_BACKEND + "/administrador/prestamos",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.cuenta.token,
            },
            body: JSON.stringify(this.prestamos[index]),
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.prestamos.shift();
          });
      }
      this.$socket.emit("envioSolicitud", this.$store.state.cuenta.idCuenta);
    },
  },
  watch: {
    prestamos() {
      this.validarBotonEnviar();
    },
  },
};
</script>

<style>
#bodyBase {
  background-color: #fffcf7;
}

.margenMaterial {
  margin-left: 10px;
  margin-right: 10px;
}

.ajusteIzquierda {
  margin-left: 10px;
}
.ajusteDerecha {
  margin-right: 10px;
}

#bodyCol4 {
  height: 520px;
}
</style>
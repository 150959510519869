<template>
  <base-material-card color="drawerColorSecondary">
    <template v-slot:heading>
      <div class="display-2 font-weight-light">Material</div>
    </template>
    <v-combobox
      v-model="materialNuevo"
      :items="this.materiales"
      item-text="nombre"
      item-value="idMaterial"
      label="Material"
      class="margenMaterial"
      @change="nuevoMaterial"
      return-object
      auto-select-first
    />
    <!-- las propiedades pertencen al objeto v-algo y los atributos se utilizan con : y lo convertimos a un atributo-->

    <v-text-field
      v-model.number="descripcionMaterial"
      v-bind:disabled="isDescripcion"
      label="Descripción"
      class="margenMaterial"
      
    />
    <v-row justify="center">
      <v-col cols="6">
        <v-select
          v-model="potenciadoNuevo"
          label="Potenciador"
          class="margenMaterial"
          v-bind:disabled="isUnidad"
          :items="potenciador"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="cantidadNuevo"
          @input="setCantidadMaterial"
          :items="cantidad"
          label="Cantidad"
          class="margenMaterial"
          open-on-clear
        ></v-select>
      </v-col>
    </v-row>
    <v-btn color="verdeBoton" :disabled="colorBotonAgregar" @click="agregar">Agregar</v-btn>
  </base-material-card>
</template>

<script>
export default {
  name: "menuMateriales",
  data() {
    return {
      colorBotonAgregar: true,
      cantidadMaterial: 0,
      materialNuevo: [],
      descripcionMaterial: "",
      potenciadoNuevo: "",
      cantidadNuevo: "",
      isDescripcion: false,
      isUnidad: false,
      cantidad: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      potenciador: [
        "N/A",
        "Pico",
        "Nano",
        "Micro",
        "Mili",
        "Kilo",
        "Mega",
        "Giga",
        "Tera",
      ],
    };
  },
  props: {
    idCuenta: String,
    materiales: Array,
    prestamos: Array,
    cuenta: {},
  },
  watch: {
    materialNuevo() {
      this.validarBotonAgregar();
    },
    descripcionMaterial() {
      this.validarBotonAgregar();
    },
    potenciador() {
      this.validarBotonAgregar();
    },
    cantidadMaterial() {
      this.validarBotonAgregar();
    },
  },
  methods: {
    /*Metodo para comprobar que la informacion para agregar prestamo es correcta.
    Una vez validado el boton se desbloquea para realizar el prestamo
    */
    validarBotonAgregar() {
      if (
        this.materialNuevo == null ||
        this.materialNuevo == "" ||
        this.materialNuevo.nombre == undefined
      ) {
        this.isDescripcion = true;
        this.isUnidad = true;
        this.colorBotonAgregar = true;
        return;
      }
      this.colorBotonAgregar = true; //true
      //Primero verificamos si ocupa descripcion
      if (this.materialNuevo.descripcion == true) {
        //verificamos si ocupa potenciador
        if (this.materialNuevo.unidadM != "PIEZA") {
          if (
            this.descripcionMaterial != "" &&
            this.materialNuevo.length != 0 &&
            this.cantidadNuevo >= 1 &&
            this.potenciadoNuevo != ""
          ) {
            this.colorBotonAgregar = false;
          }
        } else {
          if (
            this.descripcionMaterial != "" &&
            this.materialNuevo.length != 0 &&
            this.cantidadNuevo >= 1
          ) {
            this.colorBotonAgregar = false;
          }
        }
      } else if (this.materialNuevo.length != 0 && this.cantidadNuevo >= 1) {
        this.colorBotonAgregar = false;
      }
    },
    agregar() {
      let prestamoNuevo = null;
      let prestamosNuevos = [];
      //Si no se selecciona un material se cierra la funcion
      if (this.materialNuevo.length == 0) {
        return;
      }

      //Si el material necesita descripcion y no ha ingresado nada, se cierra la funcion
      if (
        this.materialNuevo.descripcion == true &&
        this.descripcionMaterial == ""
      ) {
        return;
      }

      //Si el material necesita un potenciador y no se ha ingresado nada, se cierra
      if (
        this.materialNuevo.unidadM != "PIEZA" &&
        this.materialNuevo.unidadM != "" &&
        this.potenciadoNuevo == ""
      ) {
        return;
      }

      //Si llego hasta aqui la funcion quiere decir que paso por el filtro de datos.
      for (let index = 0; index < this.cantidadNuevo; index++) {
        if (this.isDescripcion == true) {
          this.descripcionMaterial = "N/A";
        }
        if (this.isUnidad == true) {
          this.potenciadoNuevo = "N/A";
        }
        fetch(
          process.env.VUE_APP_URL_BACKEND + "/administrador/prestamos",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.cuenta.token,
            },
            body: JSON.stringify({
              idCuenta: this.cuenta.idCuenta,
              descripcion: this.descripcionMaterial,
              potenciador: this.potenciadoNuevo,
              idMaterial: this.materialNuevo.idMaterial,
              material: this.materialNuevo.nombre,
              unidadM: this.materialNuevo.unidadM,
            }),
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.prestamos.push(data);
          });
      }
      //Se limpian los datos.
      this.materialNuevo = [];
      this.potenciadoNuevo = "";
      this.cantidadNuevo = 0;
      this.descripcionMaterial = "";
      this.isUnidad = true;
      this.isDescripcion = true;
      this.cantidadMaterial = 0;
    },
    /**
     * Se agrega al campo de descripcion para validar que solo se ingresen numeros en caso
     * de tener un potenciador
     * @$event
     */
    revisarSintaxis($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (this.materialNuevo.unidadM != "PIEZA") {
        if (keyCode < 48 || keyCode > 57) {
          $event.preventDefault();
        }
      }
    },
    setCantidadMaterial(value) {
      this.cantidadMaterial = value;
    },
    /**
     * Se encarga de buscar el nuevo material como tambien habilitar si tiene descripcion o potenciador.
     */
    nuevoMaterial(index) {
      if (
        this.materialNuevo == null ||
        this.materialNuevo == "" ||
        this.materialNuevo.nombre == undefined
      ) {
        this.isDescripcion = false;
        this.isUnidad = false;
        return;
      }
      if (this.materialNuevo.descripcion == true) {
        this.isDescripcion = false;
      } else {
        this.isDescripcion = true;
      }
      if (
        this.materialNuevo.unidadM === "PIEZA" ||
        this.materialNuevo.unidadM === ""
      ) {
        this.isUnidad = true;
      } else {
        this.isUnidad = false;
      }
    },
  },
};
</script>

<style>
#bodyBase {
  background-color: #fffcf7;
}

.margenMaterial {
  margin-left: 10px;
  margin-right: 10px;
}

.ajusteIzquierda {
  margin-left: 10px;
}
.ajusteDerecha {
  margin-right: 10px;
}

#bodyCol4 {
  height: 520px;
}
</style>
